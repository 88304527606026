import { lazy } from "react";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { useSelector } from "react-redux";
import { Navigate, Outlet, RouteObject } from "react-router-dom";
import { selectCurrentUser, selectIsLoggedIn } from "store/user/user.selector";
import { REDIRECT_URI } from "utils/helper/states";

const LoginPage = lazy(() => import("./login"));
const RegisterPage = lazy(() => import("./register"));
const CheckMail = lazy(() => import("./check-mail"));
const ForgotPasswordPage = lazy(() => import("./forgot-password"));
const ResetPasswordPage = lazy(() => import("./reset-password"));

function Authentication() {
  const isLoggedIn = useSelector(selectIsLoggedIn),
    user = useSelector(selectCurrentUser);
  const redirect = sessionStorage.getItem(REDIRECT_URI);
  const redirectURI = redirect == null ? "/dashboard" : redirect;
  if (isLoggedIn && user != null) {
    if (user.type === "vendor") {
      if (user.address == null) {
        return <Navigate to={"/auth/complete-registration"} replace />;
      } else {
        return <Navigate to={redirectURI} replace />;
      }
    }
  }

  return (
    <>
      <Outlet />
    </>
  );
}

export const authRoutes: RouteObject[] = [
  {
    path: "/auth/login",
    element: <LoginPage />,
  },
  {
    path: "/auth/register",
    element: <RegisterPage />,
  },
  {
    path: "/auth/check-mail",
    element: <CheckMail />,
  },
  {
    path: "/api/auth/callback/linkedin",
    element: <LinkedInCallback />,
  },
  {
    path: "/auth/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/auth/reset-password",
    element: <ResetPasswordPage />,
  },
];

export default Authentication;

import { createBrowserRouter } from "react-router-dom";
import { lazy, Suspense } from "react";
import ErrorPage from "components/error/error.component";
import Authentication, { authRoutes } from "./auth";
import ProtectedRoutes, { protectedRoutes } from "./protected-routes";
import Root from "./root";
import InitialState from "components/toolkit/initial-state.component";
import Preloader from "components/preloader";
import FAQPage from "./faq";
import { policyLoader } from "loaders/policy";
import PolicyPage from "./policy";
import Analytics from "./analytics";

const Home = lazy(() => import("./home"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Analytics />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<Preloader />}>
            <Root />
          </Suspense>
        ),
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: "/faq",
            element: <FAQPage />,
          },
          {
            path: "/policy/:slug",
            element: (
              <Suspense fallback={<Preloader />}>
                <PolicyPage />
              </Suspense>
            ),
            loader: policyLoader,
            errorElement: <ErrorPage />,
          },
        ],
      },
      {
        element: (
          <Suspense fallback={<Preloader />}>
            <Authentication />
          </Suspense>
        ),
        loader: () => <Preloader />,
        errorElement: <ErrorPage />,
        children: authRoutes,
      },
      {
        element: (
          <Suspense fallback={<Preloader />}>
            <InitialState />
            <ProtectedRoutes />
          </Suspense>
        ),
        errorElement: <ErrorPage />,
        children: protectedRoutes,
      },
    ],
  },
]);

export default router;
